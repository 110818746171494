import React, { useState, useEffect } from 'react';
import './App.css';
import synsam from './images/Synsam.png';
import outlook from './images/Outlook.png';
import excel from './images/excel.png';
import word from './images/word.png';
import folder from './images/folder.png';
import sharepoint from './images/sharepoint.png';
const SE = "195.198.226.66";
const NO = "88.151.164.13";
const NO2 = "213.236.254.8";
const FI = "212.182.214.64";

function App() {
  const [apps, setApps] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://appswitcherapi.azurewebsites.net/api/AppSwitcherAPI');
        const data = await response.json();
        setApps(getApps(data.clientIP))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
    fetchData();
  }, []); // Empty dependency array for fetching data only once
  
    const getApps = (clientIP) => {
      let varGemensamName = null
      let varGemensamUrl = null
      let varIntranetName = null
      let varIntranetUrl = null


      if (clientIP === SE){
        varGemensamName = "Gemensam"
        varGemensamUrl = "https://synsam.sharepoint.com/sites/StoresSE/Delade%20dokument/Forms/AllItems.aspx"
        varIntranetName = "Intranätet"
        varIntranetUrl = "https://synsam.sharepoint.com/sites/Sverige"
        }else if ((clientIP === NO) || (clientIP === NO2)){
        varGemensamName = "Felles"
        varGemensamUrl = "https://synsam.sharepoint.com/sites/StoresNO/Delte%20dokumenter/Forms/AllItems.aspx"
        varIntranetName = "Intranett"
        varIntranetUrl = "https://synsam.sharepoint.com/sites/Norge"
      }else{
        console.log("not connecting from internal IP")
      }
      const outlookName = "Outlook"
      const outlookUrl = "https://outlook.office.com"

      const excelName = "Excel"
      const excelUrl = "https://www.microsoft365.com/launch/excel?auth=2"
      
      const wordName = "Word"
      const wordUrl = "https://www.microsoft365.com/launch/word?auth=2"

      const intranetName = varIntranetName
      const intranetUrl = varIntranetUrl

      const gemensamName = varGemensamName
      const gemensamUrl = varGemensamUrl

      const framesearchName = "Framesearch"
      const framesearchUrl = "https://app.snowflake.com/wyydvey/ph87410/#/streamlit-apps/DEV_SYNSQL_STREAMLIT.PUBLIC.XS6KMP6GGLNDW7RF?ref=snowsight_shared"

      const freshserviceFramesName = "Supplychain forms"
      const freshserviceFramesUrl = "https://synsam.freshservice.com/support/tickets/new"

      return {
        
        outlook: {
          name: outlookName,
          url: outlookUrl,
          image: outlook,
        },
        
        excel: {
          name: excelName,
          url: excelUrl,
          image: excel,
        },
        
        word: {
          name: wordName,
          url: wordUrl,
          image: word,
        },
        
        intranet: {
          name: intranetName,
          url: intranetUrl,
          image: sharepoint,
        },
        
        gemensam: {
          name: gemensamName,
          url: gemensamUrl,
          image: folder,
        },
        
        framesearch: {
           name: framesearchName,
           url: framesearchUrl,
           image: synsam,
         },

        freshserviceFrames: {
          name: freshserviceFramesName,
          url: freshserviceFramesUrl,
          image: synsam,
        }
        // Add more apps here in the same format
      };
    };
    
    
    const chunkApps = (apps, chunkSize) => {
      const appEntries = Object.entries(apps);
      const chunks = [];
      for (let i = 0; i < appEntries.length; i += chunkSize) {
        chunks.push(appEntries.slice(i, i + chunkSize));
      }
      return chunks;
    };
    const appRows = chunkApps(apps, 5);
    return (
      <div className="App">
      <header className="App-header">
      <img src={synsam} className="App-logo" alt="Synsam" />
      <div className="App-list">
          {appRows.map((row, rowIndex) => (
            <div key={rowIndex} className="App-row">
              {row.map(([key, app]) => (
                <button className="button" key={key} onClick={() => window.open(app.url)}>
                  {app.name}
                  <img className="icon" src={app.image} alt={app.name} />
                </button>
              ))}
            </div>
          ))}
        </div>
      </header>
    </div>
  );
}

export default App;

